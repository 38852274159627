import React from 'react';
import { FaTools } from 'react-icons/fa';

const MaintenanceMode = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <FaTools className="text-6xl text-gray-600 mb-4 animate-pulse" />
      <h1 className="text-3xl font-light text-gray-800 mb-2">We're working on it!</h1>
      <p className="text-xl text-gray-600">Our site is currently under maintenance. We'll be back soon!</p>
    </div>
  );
};

export default MaintenanceMode;