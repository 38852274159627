import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Auth/AuthContext';
import MaintenanceMode from './components/MaintenanceMode';

// Import your other components here
// import LandingPage from './components/LandingPage';
// import EventPage from './components/EventPage';
// ... other imports ...

function App() {
  const isMaintenanceMode = true; // Set this to false when you're ready to show the actual app

  if (isMaintenanceMode) {
    return <MaintenanceMode />;
  }

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Your existing routes */}
          {/* <Route path="/" element={<LandingPage />} />
          <Route path="/event/:eventId" element={<EventPage />} />
          ... other routes ... */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
