import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged, 
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail
} from 'firebase/auth';
import axios from 'axios';
import { debounce } from 'lodash';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userDataLoading, setUserDataLoading] = useState(false);

  const refreshUserData = useCallback(async (user) => {
    if (user && !userDataLoading) {
      try {
        setUserDataLoading(true);
        const token = await user.getIdToken(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.user) {
          const updatedUser = { ...user, ...response.data.user, token };
          setCurrentUser(updatedUser);
          localStorage.setItem('userData', JSON.stringify({
            ...response.data.user,
            expiresAt: Date.now() + 5 * 60 * 1000 // 5 minutes expiration
          }));
        }
      } catch (error) {
        console.error('Error refreshing user data:', error);
      } finally {
        setUserDataLoading(false);
      }
    }
  }, [userDataLoading]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.expiresAt > Date.now()) {
          setCurrentUser({ ...user, ...userData });
        } else {
          await refreshUserData(user);
        }
      } else {
        setCurrentUser(null);
        localStorage.removeItem('userData');
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [refreshUserData]);

  async function signup(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const token = await userCredential.user.getIdToken();
      
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/create-user`, {
        firebaseUid: userCredential.user.uid,
        email: userCredential.user.email
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setCurrentUser({ ...userCredential.user, token, profileComplete: false });
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  }

  async function signin(email, password) {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const token = await userCredential.user.getIdToken();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/user`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setCurrentUser({ ...userCredential.user, ...response.data.user, token });
    return response.data.user;
  }

  async function signout() {
    await signOut(auth);
    setCurrentUser(null);
  }

  const checkEmailExists = async (email) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        return true;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/check-email`, { email });
      return response.data.exists;
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const updateProfile = async (profileData) => {
    if (!currentUser || !currentUser.token) {
      throw new Error('No authentication token available');
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/update-profile`,
        profileData,
        { headers: { Authorization: `Bearer ${currentUser.token}` } }
      );

      const updatedUser = { ...currentUser, ...response.data.user };
      setCurrentUser(updatedUser);
      return updatedUser;
    } catch (error) {
      throw error;
    }
  };

  const value = {
    currentUser,
    loading,
    userDataLoading,
    signup,
    signin,
    signout,
    checkEmailExists,
    resetPassword,
    updateProfile,
    refreshUserData
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}