// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCuLWuljM61jAXvLr3mDcQYTI3B8GTEeig",
  authDomain: "bl-app-e5258.firebaseapp.com",
  projectId: "bl-app-e5258",
  storageBucket: "bl-app-e5258.appspot.com",
  messagingSenderId: "307098650096",
  appId: "1:307098650096:web:fda5aa90b87235bc6c0935",
  measurementId: "G-TLEY1EWZ8S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize Firebase Authentication

export { app, auth, analytics }; // Export the auth and analytics instances
